import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import { Modal, Button } from "react-bootstrap";
import CheckoutForm from "../Form/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../../assets/stylesheets/CourseDetails.css";
import { fetchData, createData } from "../../api/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function CourseDetails({ course, handleSnackbar }) {
  const [clientSecret, setClientSecret] = useState("");
  const [playing, setPlaying] = useState(false);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(null);
  const [completedExercises, setCompletedExercises] = useState({});
  const [audioUrl, setAudioUrl] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  useEffect(() => {
    const fetchCompletedExercises = async () => {
      try {
        const completedExerciseIds = await fetchData("listenings/" + course.id);
        setCompletedExercises(new Set(completedExerciseIds));
      } catch (error) {
        console.error("Error fetching completed exercises:", error);
      }
    };

    fetchCompletedExercises();
  }, []);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await fetchData(`programs/has_access/${course.id}`);
        setHasAccess(response.hasAccess);
      } catch (error) {
        console.error("Course access not allowed...", error);
      }
    };
    checkAccess();
  }, [course]);

  const updateAccessStatusAndCloseModal = (hasAccess) => {
    setHasAccess(hasAccess);
    handleCloseModal();
  };

  const togglePlayPause = async (exercise, index) => {
    if (currentSessionIndex === index && playing) {
      setPlaying(false);
    } else {
      await playAudio(exercise, index);
    }
  };

  const playAudio = async (exercise, index) => {
    const completedArray = Array.from(completedExercises);
    if (
      course.orderRequired == "true" &&
      index > 0 &&
      !completedArray[index - 1]
    ) {
      alert("Bitte beenden Sie die vorherige Einheit, um fortzufahren.");
      return;
    }
    const response = await fetchData(
      `exercises/stream/${course.id}/${exercise.id}`
    );
    setAudioUrl(response.url);
    setCurrentSessionIndex(index);
    setPlaying(true);
  };

  const handleApplyCoupon = async () => {
    try {
      const response = await createData("payments/apply_coupon", {
        program_id: course.id,
        coupon_code: couponCode,
      });
      if (response.id) {
        updateAccessStatusAndCloseModal(true);
        handleSnackbar("Code erfolgreich angewendet!", "success");
      } else {
        console.error("Invalid coupon code");
        handleSnackbar("Ungültiger Code", "error");
      }
    } catch (error) {
      console.error("Error applying coupon code:", error);
      handleSnackbar("Fehler bei der Verwendung des Codes", "error");
    }
  };

  const handleDownload = async (file) => {
    try {
      const response = await fetchData(
        `program/download_attachment/${course.id}/${file.id}`
      );
      const url = response.url;
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const startCourse = () => {
    if (course.exercises.length > 0) {
      playAudio(course.exercises[0], 0);
    }
  };

  const handleAudioEnded = async () => {
    try {
      const exercise_id = course.exercises[currentSessionIndex].id;
      const program_id = course.id;
      const response = await createData("/listenings", {
        program_id,
        exercise_id,
      });
      if (response.exercise_id) {
        setCompletedExercises(
          new Set([
            ...completedExercises,
            course.exercises[currentSessionIndex].id,
          ])
        );
      } else {
        console.error("Error - Kann nicht abgeschlossen werden", response);
      }
    } catch (error) {
      console.error("Error2 - Kann nicht abgeschlossen werden", error);
    }

    setPlaying(false);
    setCurrentSessionIndex(null);
  };

  const handleOpenModal = async () => {
    try {
      const response = await createData("payments/create_payment_intent", {
        program_id: course.id,
      });
      if (response.clientSecret) {
        // const data = await response.json();
        setClientSecret(response.clientSecret);
      } else {
        console.error("Failed to fetch client secret");
      }
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
    setShowModal(true);
  };

  const renderCourseAction = () => {
    // Check if the user has access and the course is not playing
    if (!hasAccess) {
      return (
        <>
          <div className="course-price">Price: {course.price}</div>
          <Button variant="primary" onClick={handleOpenModal}>
            Kurs kaufen
          </Button>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Kurs: {course.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm
                    onPaymentSuccess={() =>
                      updateAccessStatusAndCloseModal(true)
                    }
                    handleSnackbar={handleSnackbar}
                  />
                </Elements>
              ) : (
                <p>Zahlungsdetails werden geladen....</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="coupon-container">
                <input
                  type="text"
                  placeholder="oder Code eingeben"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="coupon-input"
                />
                <Button variant="primary" onClick={handleApplyCoupon}>
                  Einlösen
                </Button>
              </div>
              <div className="close-button-container">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Schließen
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    } else if (!playing) {
      return (
        course.exercises.length > 0 && (
          <button className="download-button" onClick={startCourse}>
            <PlayCircleOutlineIcon />
            KURS STARTEN
          </button>
        )
      );
    } else {
      return (
        <audio
          src={audioUrl}
          autoPlay
          controls
          onEnded={handleAudioEnded}
          controlsList="nodownload"
        />
      );
    }
  };

  return (
    <div
      className="course-details-container"
      style={{ backgroundColor: course.color }}
    >
      <div className="course-details-header">
        <h1>{course.name}</h1>
        <p>{course.description}</p>
        {/* <p className="course-instructor">von {course.instructor}</p> */}
      </div>

      <div className="course-action">
        {/* {playing ? (
          <audio
            src={audioUrl}
            autoPlay
            controls
            onEnded={handleAudioEnded}
            controlsList="nodownload"
          />
        ) : (
          <button className="download-button" onClick={startCourse}>
            <PlayCircleOutlineIcon />
            KURS STARTEN
          </button>
        )} */}
        {renderCourseAction()}
      </div>
      {course.attachments && course.attachments.length > 0 && (
        <div className="attachments-section">
          <div
            className="section-header"
            onClick={() => setShowAttachments(!showAttachments)}
          >
            <b>Anhang</b>
            <IconButton>
              {showAttachments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          {showAttachments && (
            <div className="attachments-list">
              {course.attachments.map((attachment, index) => (
                <div key={index} className="attachment-item">
                  <a
                    href={attachment.url}
                    download
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownload(attachment);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i>{attachment.name}</i>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {course.links && course.links.length > 0 && (
        <div className="links-section">
          <div
            className="section-header"
            onClick={() => setShowLinks(!showLinks)}
          >
            <b>Links</b>
            <IconButton>
              {showLinks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          {showLinks && (
            <div className="links-list">
              {course.links.map((link, index) => (
                <div key={index} className="link-item">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <i>{link}</i>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="course-sessions">
        <h2>{course.unit_name}</h2>
        {course.exercises.length === 0 ? (
          <div style={{ marginTop: "30px", textAlign: "center" }}>
            <p>Es wurden keine Übungen zu diesem Kurs hinzugefügt...</p>
          </div>
        ) : (
          course.exercises.map((exercise, index) => (
            <div
              className={`session-item ${
                completedExercises[index] ? "completed" : ""
              }`}
              key={index}
            >
              <span className="session-index">{index + 1}te</span>
              <span className="session-title">{exercise.name}</span>
              {hasAccess && (
                <IconButton
                  className="session-icon"
                  onClick={() => togglePlayPause(exercise, index)}
                  disabled={!hasAccess}
                >
                  {currentSessionIndex === index && playing ? (
                    <PauseIcon />
                  ) : (
                    <PlayArrowIcon />
                  )}
                </IconButton>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default CourseDetails;
