import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const PRIVACY_POLICY_KEY = "privacyPolicyAccepted";
const PRIVACY_POLICY_TIMESTAMP = "privacyPolicyTimestamp";

function PrivacyPolicyModal({ show, onHide }) {
  const [rememberChoice, setRememberChoice] = useState(false);

  const handleAccept = () => {
    const currentTimestamp = new Date().toISOString();

    if (rememberChoice) {
      localStorage.setItem(PRIVACY_POLICY_KEY, "true");
      localStorage.setItem(PRIVACY_POLICY_TIMESTAMP, currentTimestamp);
    }

    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Datenschutzerklärung & AGB
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="pdf-container"
          style={{
            height: "60vh",
            border: "1px solid #dee2e6",
            borderRadius: "4px",
          }}
        >
          <iframe
            src="/files/agb_ernaapp_vertrag.pdf"
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Privacy Policy PDF"
          />
        </div>

        <div className="mt-3 d-flex justify-content-end">
          <a
            href="/files/agb_ernaapp_vertrag.pdf"
            download
            className="text-primary text-decoration-none"
          >
            PDF herunterladen
          </a>
        </div>

        <Form.Check
          type="checkbox"
          id="remember-choice"
          className="mt-3"
          label="Auswahl speichern"
          checked={rememberChoice}
          onChange={(e) => setRememberChoice(e.target.checked)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAccept} className="w-100">
          Ich habe die AGB gelesen und stimme zu
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyPolicyModal;
