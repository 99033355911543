import React from "react";
import { Navigate } from "react-router-dom";
import Menu from "./views/Menu/Menu";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";
import Categories from "./pages/Categories/Categories";
import Settings from "./pages/Settings/Settings";
import ResetPasswordForm from "./components/Form/ResetPasswordForm";
import UserManagement from "./pages/Dashboard/UserManagement";
import Impressum from "./pages/Impressum/Impressum";
import DataProtection from "./pages/DataProtection/DataProtection";

const routes = [
  {
    path: "/",
    element: (
      <>
        <Navigate to="/dashboard" />
        <Menu content={<Dashboard />} />
      </>
    ),
    isProtected: true,
    isAdminRoute: false,
  },
  {
    path: "/dashboard",
    element: <Menu content={<Dashboard />} />,
    isProtected: true,
    isAdminRoute: false,
  },
  {
    path: "/management",
    element: <Menu content={<UserManagement />} />,
    isProtected: true,
    isAdminRoute: true,
  },
  {
    path: "/categories",
    element: <Menu content={<Categories />} />,
    isProtected: true,
    isAdminRoute: true,
  },
  // {
  //   path: "/programs/:id",
  //   element: <Menu content={<Program />} />,
  //   isProtected: true,
  // },
  // {
  //   path: "/myphysio",
  //   element: <Menu content={<MyPhysio />} />,
  //   isProtected: true,
  // },
  // {
  //   path: "/preferences",
  //   element: (
  //     <>
  //       <Navigate to="/preferences/profile" />
  //       <Menu
  //         content={<Sidebar contentdisplay={<Profile />} title="Profile" />}
  //       />
  //     </>
  //   ),
  //   isProtected: true,
  // },
  {
    path: "/profile",
    element: <Menu content={<Profile />} />,
    isProtected: true,
    isAdminRoute: false,
  },
  {
    path: "/preferences",
    element: <Menu content={<Settings />} />,
    isProtected: true,
    isAdminRoute: false,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPasswordForm />,
    isProtected: false, // Ensure this is accessible without authentication
    isAdminRoute: false,
  },

  // {
  //   path: "/preferences/about",
  //   element: (
  //     <Menu
  //       content={<Sidebar contentdisplay={<About />} title="Über sophyapp" />}
  //     />
  //   ),
  //   isProtected: true,
  // },
  // {
  //   path: "*",
  //   element: (
  //     <>
  //       <Navigate to="/dashboard" />
  //       <Menu content={<Programs />} />
  //     </>
  //   ),
  //   isProtected: true,
  // },
];

export default routes;
