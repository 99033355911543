import React, { useState, useEffect } from "react";
import "../../assets/stylesheets/Login.css";
import PropTypes from "prop-types";
import ResetPassword from "../../components/Modals/ResetPasswordModal";
import NewRegistration from "../../components/Modals/NewRegistrationModal";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { login } from "../../api/api";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ImpressumModal from "../../components/Modals/ImpressumModal";
import PrivacyPolicyModal from "../../components/Modals/PrivacyPolicyModal";

const styles = {
  loginContainer: {
    marginTop: "10%",
    paddingTop: "48px",
    marginLeft: "32%",
    marginRight: "32%",
    height: "46.5vh",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1pt solid #E5E7E8",
    borderRadius: "5pt",
    opacity: "1",
  },
  loginImage: {
    position: "absolute",
    left: "60vw",
    right: 0,
  },
  loginFooter: {
    position: "fixed",
    bottom: "1vh",
    textAlign: "center",
    width: "100%",
    color: "#7C878E",
  },
};

async function loginUser(credentials) {
  try {
    const result = await login(credentials);
    return result;
  } catch (error) {
    // Handle login error here (e.g., show error message)
  }
}
const PRIVACY_POLICY_KEY = "privacyPolicyAccepted";
export default function Login({ setToken, application, imagePath }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalRegShow, setModalRegShow] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationFailed, setRegistrationFailed] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [passwordFailed, setPasswordFailed] = useState(false);
  const [impressumModalShow, setImpressumModalShow] = useState(false);
  const [privacyPolicyModalShow, setPrivacyPolicyModalShow] = useState(false);

  useEffect(() => {
    const hasAcceptedPolicy = localStorage.getItem(PRIVACY_POLICY_KEY);
    if (!hasAcceptedPolicy) {
      setPrivacyPolicyModalShow(true);
    }
  }, []);

  const { t, i18n } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
    });
    if (response && response.access_token) {
      setToken(response.access_token);
    } else {
      setLoginFailed(true);
    }
  };

  return (
    <Container fluid>
      <Row className="row d-flex justify-content-center">
        <Col md={12}>
          <div className="login-page">
            <div className="login-wrapper loginContainer">
              <img
                src={imagePath}
                alt="sophyapp logo"
                className="sophyappimage"
              />
              <form onSubmit={handleSubmit}>
                <div className="login-field-wrapper">
                  <div>
                    <input
                      type="text"
                      placeholder={t("user.user")}
                      className="input-field-login"
                      onChange={(e) =>
                        setUserName(e.target.value.toLowerCase())
                      }
                      autoFocus
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder={t("user.password")}
                      className="input-field-login"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="forgot-password-link-wrapper">
                  <a
                    href="#"
                    className="forgot-password-link"
                    onClick={() => setModalShow(true)}
                  >
                    {t("password.forgot")}
                  </a>
                </div>
                <div>
                  <center>
                    <button type="submit" className="login-button">
                      {t("user.sign-in")}
                    </button>
                  </center>
                </div>
                <div className="new-registration-text">
                  {t("user.no-account")}
                </div>
                <div>
                  <center>
                    <a
                      href="#"
                      className="new-account-link"
                      onClick={() => setModalRegShow(true)}
                    >
                      {t("user.register-now")}
                    </a>
                  </center>
                </div>
              </form>
              {application === "sophyapp" ? (
                <div className="login-image">
                  <img
                    src="/images/hdi_illu_sophyapp_web-800x800.webp"
                    alt="sophyapp demonstration"
                    style={styles.loginImage}
                  />
                </div>
              ) : null}
            </div>
            <div className="login-footer" style={styles.loginFooter}>
              <Router>
                <a href="#" onClick={() => setPrivacyPolicyModalShow(true)}>
                  {t("others.privacy")}
                </a>{" "}
                •{" "}
                {/* <a href="#" onClick={() => setImpressumModalShow(true)}>
                  Impressum
                </a>{" "}
                •{" "} */}
                <a
                  href="https://hoferdigital.at/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* {t("others.support")} */}© hoferdigital gmbh
                </a>
              </Router>
            </div>
            <Alert
              variant="danger"
              className="alert-fixed"
              show={loginFailed}
              onClose={() => setLoginFailed(false)}
              dismissible
            >
              <Alert.Heading>{t("user.password-wrong")}</Alert.Heading>
              <p>{t("user.check-form")}</p>
            </Alert>
            <Alert
              variant="success"
              className="alert-fixed"
              show={registrationSuccess}
              onClose={() => setRegistrationSuccess(false)}
              dismissible
            >
              <Alert.Heading>{t("user.must-registrate")}</Alert.Heading>
              <p>{t("user.check-mail")}</p>
            </Alert>
            <Alert
              variant="danger"
              className="alert-fixed"
              show={registrationFailed}
              onClose={() => setRegistrationFailed(false)}
              dismissible
            >
              <Alert.Heading>{t("user.no-registration")}</Alert.Heading>
              <p>{t("user.already-registered")}</p>
            </Alert>
            <Alert
              variant="success"
              className="alert-fixed"
              show={passwordSuccess}
              onClose={() => setPasswordSuccess(false)}
              dismissible
            >
              <Alert.Heading>{t("user.reset-password")}</Alert.Heading>
              <p>{t("user.reset-password2")}</p>
            </Alert>
            <Alert
              variant="danger"
              className="alert-fixed"
              show={passwordFailed}
              onClose={() => setPasswordFailed(false)}
              dismissible
            >
              <Alert.Heading>{t("user.reset-password")}</Alert.Heading>
              <p>{t("user.reset-password-fail")}</p>
            </Alert>
            <ResetPassword
              show={modalShow}
              setPasswordSuccess={setPasswordSuccess}
              setPasswordFailed={setPasswordFailed}
              application={application}
              onHide={() => setModalShow(false)}
            ></ResetPassword>
            <NewRegistration
              show={modalRegShow}
              setRegistrationSuccess={setRegistrationSuccess}
              setRegistrationFailed={setRegistrationFailed}
              applicationName={application}
              onHide={() => setModalRegShow(false)}
            ></NewRegistration>
            <PrivacyPolicyModal
              show={privacyPolicyModalShow}
              onHide={() => setPrivacyPolicyModalShow(false)}
            />
            <ImpressumModal
              show={impressumModalShow}
              onHide={() => setImpressumModalShow(false)}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
