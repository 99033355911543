import React from "react";
import { Modal, Button } from "react-bootstrap";

function ImpressumModal({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Impressum</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Add your Impressum details here */}
        <p>Impressum Daten folgen...</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImpressumModal;
